import { Debt } from '../../interfaces';

import { areDebtsShared } from '../debt/areDebtsShared';
import { getEligibleDebts } from '../debt/getEligibleDebts';

export const getEligibleHouseholdDebts = (borrowerDebts: Debt[] = [], spouseDebts: Debt[] = []): Debt[] => {
  const borrowerEligibleDebts: Debt[] = getEligibleDebts(borrowerDebts);
  const spouseEligibleDebts: Debt[] = getEligibleDebts(spouseDebts);

  const eligibleHouseholdDebts: Debt[] = [];

  for (const borrowerDebt of borrowerEligibleDebts) {
    if (['individual', 'primary'].includes(borrowerDebt.ecoaDesignator)) {
      eligibleHouseholdDebts.push(borrowerDebt);
    }

    if (borrowerDebt.ecoaDesignator === 'jointContractLiability') {
      const isDebtSharedWithSpouse = spouseEligibleDebts.find((spouseDebt) => areDebtsShared(borrowerDebt, spouseDebt));

      if (isDebtSharedWithSpouse) {
        eligibleHouseholdDebts.push(borrowerDebt);
      }
    }
  }

  for (const spouseDebt of spouseEligibleDebts) {
    if (['individual', 'primary'].includes(spouseDebt.ecoaDesignator)) {
      const isDebtAccountedForByBorrower = eligibleHouseholdDebts.find((debt) => areDebtsShared(debt, spouseDebt));

      if (!isDebtAccountedForByBorrower) {
        eligibleHouseholdDebts.push(spouseDebt);
      }
    }
  }

  return eligibleHouseholdDebts;
};
