import { Collection } from '../../interfaces';

import { areCollectionsShared } from '../collection/areCollectionsShared';
import { getEligibleCollections } from '../collection/getEligibleCollections';

export const getEligibleHouseholdCollections = (
  borrowerCollections: Collection[] = [],
  spouseCollections: Collection[] = [],
): Collection[] => {
  const borrowerEligibleCollections: Collection[] = getEligibleCollections(borrowerCollections);
  const spouseEligibleCollections: Collection[] = getEligibleCollections(spouseCollections);

  const eligibleHouseholdCollections: Collection[] = [];

  for (const borrowerCollection of borrowerEligibleCollections) {
    if (['individual', 'primary'].includes(borrowerCollection.ecoaDesignator)) {
      eligibleHouseholdCollections.push(borrowerCollection);
    }

    if (borrowerCollection.ecoaDesignator === 'jointContractLiability') {
      const isCollectionSharedWithSpouse = spouseEligibleCollections.find((spouseCollection) =>
        areCollectionsShared(borrowerCollection, spouseCollection),
      );

      if (isCollectionSharedWithSpouse) {
        eligibleHouseholdCollections.push(borrowerCollection);
      }
    }
  }

  for (const spouseCollection of spouseEligibleCollections) {
    if (['individual', 'primary'].includes(spouseCollection.ecoaDesignator)) {
      const isCollectionAccountedForByBorrower = eligibleHouseholdCollections.find((collection) =>
        areCollectionsShared(collection, spouseCollection),
      );

      if (!isCollectionAccountedForByBorrower) {
        eligibleHouseholdCollections.push(spouseCollection);
      }
    }
  }

  return eligibleHouseholdCollections;
};
