import { Cashflow, PlaidTransaction } from '../../interfaces';

export const getCashflowByDate = (plaidTransactions: PlaidTransaction[], date: string): Cashflow => {
  if (!plaidTransactions || plaidTransactions.length === 0) return { amount: 0, transactionIds: [], date };

  const plaidTransactionsByDate = plaidTransactions.filter((plaidTransaction) => plaidTransaction.date === date);
  const netCashflow = plaidTransactionsByDate.reduce((sum, plaidTransaction) => sum + plaidTransaction.amount, 0);

  const cashflow: Cashflow = {
    amount: netCashflow * -1,
    date,
    transactionIds: plaidTransactionsByDate.map((plaidTransaction) => plaidTransaction.id),
  };

  return cashflow;
};
