import {
  PlaidAccount,
  PlaidAccountBalance,
  PlaidAccountEligibility,
  PlaidAccountOwner,
  PlaidTransaction,
} from '../../interfaces';
import { disqualificationReasons as dqReasonEnum } from '../../enums';
import { getAccountAgeInMonths } from './getAccountAgeInMonths';
import { getAverageIncome } from './getAverageIncome';
import { getCurrentBalance } from './getCurrentBalance';
import { getNumberOfAccountOwners } from './getNumberOfAccountOwners';
import { getIncomeTransactions } from './getIncomeTransactions';
import { getAverageDaysBetweenIncomeTransactions } from './getAverageDaysBetweenIncomeTransactions';
import { getNextPayday } from './getNextPayday';
import { getTwoMostRecentCashflowsOnPaydaysAndNextBusinessDayAfter } from './getTwoMostRecentCashflowsOnPaydaysAndNextBusinessDayAfter';

export const isEligible = (
  plaidAccount: PlaidAccount,
  plaidTransactions: PlaidTransaction[],
  plaidAccountBalances: PlaidAccountBalance[],
  plaidAccountOwners: PlaidAccountOwner[],
): PlaidAccountEligibility => {
  const accountAgeInMonths = getAccountAgeInMonths(plaidTransactions);
  const averageIncome = getAverageIncome(plaidTransactions);
  const currentBalance = getCurrentBalance(plaidAccountBalances);
  const numberOfAccountOwners = getNumberOfAccountOwners(plaidAccountOwners);
  const incomeTransactions = getIncomeTransactions(plaidTransactions, true);
  const averageDaysBetweenIncomeTransactions = getAverageDaysBetweenIncomeTransactions(plaidTransactions);
  const nextPayday = getNextPayday(plaidTransactions);
  const twoMostRecentCashflowsOnPaydaysAndNextBusinessDayAfter = getTwoMostRecentCashflowsOnPaydaysAndNextBusinessDayAfter(
    plaidTransactions,
  );

  const disqualificationReasons: dqReasonEnum[] = [];

  if (accountAgeInMonths < 3) {
    disqualificationReasons.push(dqReasonEnum.plaidAccountWasTooNew);
  }

  if (currentBalance <= 0) {
    disqualificationReasons.push(dqReasonEnum.plaidAccountBalanceWasTooLow);
  }

  if (numberOfAccountOwners !== 1) {
    disqualificationReasons.push(dqReasonEnum.plaidAccountDidNotHaveExactlyOneOwner);
  }

  if (plaidAccount?.subType !== 'checking') {
    disqualificationReasons.push(dqReasonEnum.plaidAccountWasNotCheckingAccount);
  }

  if (incomeTransactions?.length < 6) {
    disqualificationReasons.push(dqReasonEnum.plaidAccountNumberOfIncomeTransactionsWasTooLow);
  }

  if (averageIncome < 1500) {
    disqualificationReasons.push(dqReasonEnum.plaidAccountAverageIncomeWasTooLow);
  }

  const cashflowMinimum = 150;
  const cashflowNearPaydayIsTooLow =
    twoMostRecentCashflowsOnPaydaysAndNextBusinessDayAfter &&
    twoMostRecentCashflowsOnPaydaysAndNextBusinessDayAfter.some(
      ({ cashflowOnPayday, cashflowOnBusinessDayAfterPayday }) =>
        (cashflowOnPayday?.amount + cashflowOnBusinessDayAfterPayday?.amount) / 2 < cashflowMinimum,
    );

  if (cashflowNearPaydayIsTooLow) {
    disqualificationReasons.push(dqReasonEnum.plaidAccountCashflowNearPaydayWasTooLow);
  }

  const plaidAccountEligibility: PlaidAccountEligibility = {
    accountAgeInMonths,
    accountSubType: plaidAccount?.subType ?? null,
    averageDaysBetweenIncomeTransactions,
    averageIncome,
    currentBalance,
    disqualificationReasons,
    incomeTransactions,
    isEligible: disqualificationReasons.length === 0,
    numberOfAccountOwners,
    nextPayday,
    twoMostRecentCashflowsOnPaydaysAndNextBusinessDayAfter,
  };

  return plaidAccountEligibility;
};
