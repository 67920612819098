import { LenderConcession } from '../../interfaces/lenderConcession';
import { AMEX } from './amex';
import { AnnTaylor } from './annTaylor';
import { BankOfAmerica } from './bankOfAmerica';
import { Barclays } from './barclays';
import { CapitalOne } from './capitalOne';
import { Chase } from './chase';
import { CITI } from './citi';
import { ComenityBank } from './comenityBank';
import { Costco } from './costco';
import { CreditOne } from './creditOne';
import { DefaultConcession } from './defaultConcession';
import { Discover } from './discover';
import { FirstBank } from './firstBank';
import { HomeDepot } from './homeDepot';
import { HSBC } from './hsbc';
import { Kohls } from './kohls';
import { Macys } from './macys';
import { NavyFederalCreditUnion } from './navyFederalCreditUnion';
import { Nordstrom } from './nordstrom';
import { OneMainFinancial } from './oneMainFinancial';
import { PNC } from './pnc';
import { QVC } from './qvc';
import { Sears } from './sears';
import { Synchrony } from './synchrony';
import { Target } from './target';
import { TDBank } from './tdBank';
import { USAA } from './usaa';
import { USBank } from './usBank';
import { WellsFargo } from './wellsFargo';

const lenderConcessions: LenderConcession[] = [
  AMEX,
  AnnTaylor,
  BankOfAmerica,
  Barclays,
  CapitalOne,
  Chase,
  CITI,
  ComenityBank,
  Costco,
  CreditOne,
  Discover,
  FirstBank,
  HomeDepot,
  Kohls,
  HSBC,
  Macys,
  NavyFederalCreditUnion,
  Nordstrom,
  OneMainFinancial,
  PNC,
  QVC,
  Sears,
  Synchrony,
  Target,
  TDBank,
  USAA,
  USBank,
  WellsFargo,
];

export const getLenderConcession = (lenderName: string): LenderConcession =>
  lenderConcessions.find((lenderConcession) => lenderConcession.lenderNameRegEx.test(lenderName)) || DefaultConcession;
