import { DateTime } from 'luxon';
import { PlaidAccountBalance } from '../../interfaces';

export const getCurrentBalance = (plaidAccountBalances: PlaidAccountBalance[]): number => {
  if (!plaidAccountBalances || plaidAccountBalances.length === 0) return null;

  if (plaidAccountBalances.some((item) => item.current === null || item.current === undefined || !item.createdAt))
    return null;

  const plaidAccountBalancesSortedByCreatedAtDesc = plaidAccountBalances.sort((a, b) => {
    const aDate = DateTime.fromISO(a.createdAt);
    const bDate = DateTime.fromISO(b.createdAt);

    return aDate > bDate ? -1 : 1;
  });

  return plaidAccountBalancesSortedByCreatedAtDesc[0].current;
};
