import { Debt } from '../../interfaces';
import { DateTime } from 'luxon';

export const isAtLeast6MonthsOld = (debt: Debt): boolean => {
  if (!debt || !debt.dateOpened) return false;

  const dateOpened = DateTime.fromISO(debt.dateOpened);

  const diff = dateOpened.diffNow('months');

  return Math.abs(diff.months) >= 6;
};
