import { CashflowOnPaydayAndNextBusinessDayAfter, PlaidTransaction } from '../../interfaces';
import { DateTime } from 'luxon';
import { getCashflowsOnPaydaysAndNextBusinessDayAfter } from './getCashflowsOnPaydaysAndNextBusinessDayAfter';

export const getTwoMostRecentCashflowsOnPaydaysAndNextBusinessDayAfter = (
  plaidTransactions: PlaidTransaction[],
): CashflowOnPaydayAndNextBusinessDayAfter[] => {
  const cashflowsOnPaydaysAndNextBusinessDayAfter = getCashflowsOnPaydaysAndNextBusinessDayAfter(plaidTransactions);

  if (!cashflowsOnPaydaysAndNextBusinessDayAfter || cashflowsOnPaydaysAndNextBusinessDayAfter.length === 0) return null;

  const cashflowsSortedByCashflowOnPaydayDesc = cashflowsOnPaydaysAndNextBusinessDayAfter.sort((a, b) =>
    DateTime.fromISO(b.cashflowOnPayday.date).diff(DateTime.fromISO(a.cashflowOnPayday.date)).as('days'),
  );

  const twoMostRecentPaydays = cashflowsSortedByCashflowOnPaydayDesc.slice(0, 2);

  return twoMostRecentPaydays;
};
