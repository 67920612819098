export enum routers {
  aeon = 'aeon',
  commonBondCommunities = 'cbond',
  dreamKeyPartners = 'dreamkey',
  eahHousing = 'eah',
  edenHousing = 'eden',
  fakeRouter = 'fakerouter',
  fpsFinancial = 'fps',
  fnbo = 'fnbo',
  lendingClub = 'lendingclub',
  midpenHousing = 'midpen',
  nationalHousingTrust = 'nht',
  nfcc = 'nfcc',
  oneMainFinancial = 'omf',
  phippsHouses = 'phipps',
  prosper = 'prosper',
  satelliteAffordableHousing = 'saha',
  synchrony = 'smono',
  theCommunityBuilders = 'tcb',
  wellsFargo = 'wellsfargo',
}
