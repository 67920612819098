import { getAllHouseholdAutoLoans } from './getAllHouseholdAutoLoans';
import { getAllHouseholdMortgages } from './getAllHouseholdMortgages';
import { getAllHouseholdOtherDebts } from './getAllHouseholdOtherDebts';
import { getAllHouseholdStudentLoans } from './getAllHouseholdStudentLoans';
import { getAllOpenHouseholdIneligibleDebts } from './getAllOpenHouseholdIneligibleDebts';
import { getEligibleHouseholdDebts } from './getEligibleHouseholdDebts';

export const debtService = {
  getAllHouseholdAutoLoans,
  getAllHouseholdMortgages,
  getAllHouseholdOtherDebts,
  getAllHouseholdStudentLoans,
  getAllOpenHouseholdIneligibleDebts,
  getEligibleHouseholdDebts,
};
