import { CashflowOnPaydayAndNextBusinessDayAfter, PlaidTransaction } from '../../interfaces';
import { getNextBusinessDay } from '../date/getNextBusinessDay';
import { getCashflowByDate } from './getCashflowByDate';
import { getIncomeTransactions } from './getIncomeTransactions';

export const getCashflowsOnPaydaysAndNextBusinessDayAfter = (
  plaidTransactions: PlaidTransaction[],
): CashflowOnPaydayAndNextBusinessDayAfter[] => {
  if (!plaidTransactions || plaidTransactions.length === 0) return null;

  const incomeTransactions = getIncomeTransactions(plaidTransactions, true);
  if (!incomeTransactions || incomeTransactions.length === 0) return null;

  const cashflows: CashflowOnPaydayAndNextBusinessDayAfter[] = incomeTransactions.map((incomeTransaction) => {
    const cashflowOnPayday = getCashflowByDate(plaidTransactions, incomeTransaction.date);
    const nextBusinessDayAfterPayday = getNextBusinessDay(incomeTransaction.date);
    const cashflowOnBusinessDayAfterPayday = getCashflowByDate(plaidTransactions, nextBusinessDayAfterPayday);

    return { cashflowOnPayday, cashflowOnBusinessDayAfterPayday };
  });

  return cashflows;
};
