import { PlaidTransaction } from '../../interfaces';
import { incomeCategories } from './incomeCategories';

export const isIncome = (plaidTransaction: PlaidTransaction): boolean => {
  if (!plaidTransaction.category) {
    return false;
  }

  const isIncomeCategory = incomeCategories.some((category) => {
    const incomeCategoryString = category.join(' ');
    const transactionCategoryString = plaidTransaction.category.join(' ');

    return incomeCategoryString === transactionCategoryString;
  });

  const isACreditOfAtLeastTwoHundredDollars = plaidTransaction.amount <= -200;

  return isIncomeCategory && isACreditOfAtLeastTwoHundredDollars;
};
