import { DateTime } from 'luxon';
import { PlaidTransaction } from '../../interfaces';
import { getIncomeTransactions } from './getIncomeTransactions';

export const getAverageIncome = (plaidTransactions: PlaidTransaction[]): number => {
  if (!plaidTransactions || plaidTransactions.length === 0) return null;

  if (plaidTransactions.some((transaction) => !transaction.date || !Number.isFinite(transaction.amount))) return null;

  const incomeTransactions = getIncomeTransactions(plaidTransactions);

  if (!incomeTransactions || incomeTransactions.length === 0) return null;

  const totalIncome = incomeTransactions.reduce((sum, transaction) => sum + transaction.amount, 0);
  const numberOfMonths = incomeTransactions
    .map((transaction) => DateTime.fromISO(transaction.date).toFormat('yyyy-MM'))
    .reduce((monthsGroupedByOccurrences, month) => {
      if (monthsGroupedByOccurrences[month]) {
        monthsGroupedByOccurrences[month] += 1;
      } else {
        monthsGroupedByOccurrences[month] = 1;
      }
      return monthsGroupedByOccurrences;
    }, {} as { [key: string]: number });

  const averageIncome = totalIncome / Object.keys(numberOfMonths).length;

  return averageIncome * -1;
};
