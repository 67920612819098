import { counselorGenieStages, environments, routers } from '../../enums';

export const nfccPreLeadNumber = '+1-844-865-2461';

export const getCounselorGeniePhoneNumber = (
  router: routers | string,
  env: environments | string,
  stage?: counselorGenieStages | string,
): string => {
  if (env === environments.lutheranSocialServiceOfMinnesota) {
    if (router === routers.commonBondCommunities) {
      return '+1-833-227-1667';
    }

    if (router === routers.aeon) {
      return '+1-833-227-1702';
    }
  }

  if (env === environments.consumerCreditCounselingServicesOfMaryland) {
    return '+1-800-642-2227';
  }

  if (env === environments.creditOrg) {
    if (router === routers.phippsHouses) {
      return '+1-866-209-8366';
    }

    if (router === routers.theCommunityBuilders) {
      return '+1-833-997-1223';
    }

    if (router === routers.edenHousing) {
      return '+1-888-916-2841';
    }

    if (router === routers.fpsFinancial) {
      return '+1-848-233-2010';
    }

    if (router === routers.oneMainFinancial) {
      return '+1-848-233-2011';
    }

    if (router === routers.eahHousing) {
      return '+1-877-298-8574';
    }

    if (router === routers.satelliteAffordableHousing) {
      return '+1-877-298-8572';
    }

    if (router === routers.midpenHousing) {
      return '+1-888-828-7018';
    }
  }

  if (env === environments.cambridgeCreditCounseling) {
    if (router === routers.lendingClub) {
      return '+1-833-236-7191';
    }

    if (router === routers.prosper) {
      return '+1-833-261-0402';
    }

    if (router === routers.synchrony) {
      return '+1-800-483-0589';
    }
  }

  if (env === environments.consumerEducationServicesInc) {
    if (router === routers.dreamKeyPartners) {
      return '+1-888-373-3985';
    }

    if (router === routers.wellsFargo) {
      return '+1-855-716-2444';
    }

    if (router === routers.nfcc) {
      if (stage === counselorGenieStages.postLead) {
        return '+1-866-861-0792';
      }

      if (stage === counselorGenieStages.preLead) {
        return nfccPreLeadNumber;
      }
    }
  }

  if (env === environments.apprisen) {
    if (router === routers.nfcc) {
      if (stage === counselorGenieStages.postLead) {
        return '+1-888-898-9167';
      }

      if (stage === counselorGenieStages.preLead) {
        return nfccPreLeadNumber;
      }
    }
  }

  if (env === environments.americanFinancialServices) {
    if (router === routers.nfcc) {
      if (stage === counselorGenieStages.postLead) {
        return '+1-888-818-5021';
      }

      if (stage === counselorGenieStages.preLead) {
        return nfccPreLeadNumber;
      }
    }

    if (router === routers.fnbo) {
      return '+1-833-262-4477';
    }
  }

  if (env === environments.main) {
    if (stage === counselorGenieStages.postLead) {
      return '+1-888-888-8880';
    }

    if (stage === counselorGenieStages.preLead) {
      return '+1-888-888-8881';
    }
  }

  if (env === environments.staging) {
    if (stage === counselorGenieStages.postLead) {
      return '+1-888-888-8882';
    }

    if (stage === counselorGenieStages.preLead) {
      return '+1-888-888-8883';
    }
  }
};
