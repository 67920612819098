import { Debt } from '../../interfaces';
import { areDebtsShared } from './areDebtsShared';
import { getDebtsByAccountTypes } from './getDebtsByAccountTypes';
import { getOpenDebts } from './getOpenDebts';

export const getAllOpenHouseholdDebtsByAccountTypes = (
  borrowerDebts: Debt[] = [],
  spouseDebts: Debt[] = [],
  accountTypes: string[],
): Debt[] => {
  const borrowerOpenByAccountTypeDebts: Debt[] = getOpenDebts(getDebtsByAccountTypes(borrowerDebts, ...accountTypes));

  const spouseOpenByAccountTypeDebts: Debt[] = getOpenDebts(getDebtsByAccountTypes(spouseDebts, ...accountTypes));

  const householdDebts: Debt[] = [];

  for (const borrowerDebt of borrowerOpenByAccountTypeDebts) {
    if (['individual', 'primary', 'jointContractLiability'].includes(borrowerDebt.ecoaDesignator)) {
      householdDebts.push(borrowerDebt);
    }
  }

  for (const spouseDebt of spouseOpenByAccountTypeDebts) {
    if (['individual', 'primary', 'jointContractLiability'].includes(spouseDebt.ecoaDesignator)) {
      const isDebtAccountedForByBorrower = householdDebts.find((debt) => areDebtsShared(debt, spouseDebt));

      if (!isDebtAccountedForByBorrower) {
        householdDebts.push(spouseDebt);
      }
    }
  }

  return householdDebts;
};
