export enum disqualificationReasons {
  applicationInformationInaccurate = 'The application information was inaccurate',
  allOffersWereDeclined = 'All offers were declined by creditors',
  autoclosed = 'Auto-closed (did not update in 5 days)',
  cashflowTooLow = 'The cashflow was too low',
  cashflowTooHigh = 'The cashflow was too high',
  couldNotVerifyConsumerIdentity = 'Could not verify consumer identity',
  couldNotVerifyExpenses = 'Could not verify expenses',
  couldNotVerifyIncome = 'Could not verify income',
  currentExpensesTooHigh = 'The current expenses were too high',
  currentExpensesTooLow = 'The current expenses were too low',
  getCreditReportsFailed = 'System error - failed to retrieve credit reports from Transunion API',
  getDecisionFailed = 'System error - failed to get decision from Decision API',
  getEligibleCollectionsAndDebtsFailed = 'System error - failed to retrieve eligible collections and debts from Debt API',
  getExpenseEstimatesFailed = 'System error - failed to get expense estimates from Expenses API',
  getIncomePredictionFailed = 'System error - failed to get income prediction from Income API',
  getProposedBudgetFailed = 'System error - failed to get proposed budget from Budget API',
  noEligibleCollectionsOrDebts = 'No eligible collections or debts were found',
  noHardshipsWereSelected = 'No hardships were selected',
  noHitFromTransunion = 'No credit report was hit from Transunion API',
  noTradesFromTransunion = 'No trades were returned from Transunion API',
  notEnoughEligibleCollectionsOrDebts = 'Not enough eligible collections or debts were found',
  notLicensedInBorrowersHomeState = "Not licensed in borrower's home state",
  other = 'Other',
  plaidAccountAverageCashflowOnPaydayWasTooLow = 'The Plaid Account average cashflow on payday was too low',
  plaidAccountCashflowNearPaydayWasTooLow = 'The Plaid Account cashflow near payday was too low',
  plaidAccountAverageIncomeWasTooLow = 'The Plaid Account average income was too low',
  plaidAccountBalanceWasTooLow = 'The Plaid Account balance was too low',
  plaidAccountNumberOfIncomeTransactionsWasTooLow = 'The Plaid Account number of income transactions was too low',
  plaidAccountDidNotHaveExactlyOneOwner = 'The Plaid Account did not have exactly 1 owner',
  plaidAccountWasNotCheckingAccount = 'The Plaid Account was not a checking account',
  plaidAccountWasTooNew = 'The Plaid Account was too new',
  proposedBudgetWasUnrealistic = 'Proposed budget was unrealistic',
  proposedCashflowTooLow = 'The proposed cash flow was too low',
  proposedExpensesTooHigh = 'The proposed expenses were too high',
  proposedExpensesTooLow = 'The proposed expenses were too low',
  selectedForReductionDebtBalanceTooLow = 'The selected for reduction debt balance was too low',
  selfReportedIncomeTooLow = 'The self-reported income was too low',
  theAccountHadNotChargedOff = 'The account had not charged off',
  theReductionWasTooMuch = 'The reduction was too much',
  theTermWasTooLong = 'The term was too long',
  totalEligibleDebtTooLow = 'The total amount of eligible debt was too low',
  unableToContactTheLender = 'Unable to contact the lender',
  unableToWorkWithCreditGenie = 'Unable to work with Credit Genie',
  verifiedIncomeTooHigh = 'The verified income was too high',
  verifiedIncomeTooLow = 'The verified income was too low',
}
