export enum applicationStatuses {
  inProgress = 'In Progress',
  acceptOffer = 'Accept Offer',
  connectBankAccount = 'Account Connection',
  confirmPaymentAccount = 'Confirm Payment Account',
  signContract = 'Sign Contract',
  transfer = 'Transfer',
  transferred = 'Transferred',
  disqualified = 'Disqualified',
}
