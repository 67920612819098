import { Debt } from '../../interfaces';
import { isAtLeast6MonthsOld } from './isAtLeast6MonthsOld';
import { isCurrentBalanceGreaterThan } from './isCurrentBalanceGreaterThan';
import { isEligibleECOADesignator } from './isEligibleECOADesignator';
import { isEligibleType } from './isEligibleType';
import { isOpen } from './isOpen';

export const isEligible = (debt: Debt): boolean =>
  isAtLeast6MonthsOld(debt) &&
  isCurrentBalanceGreaterThan(debt) &&
  isEligibleECOADesignator(debt) &&
  isEligibleType(debt) &&
  isOpen(debt);
