import { Debt } from '../../interfaces';

export const areDebtsShared = (...debts: Debt[]): boolean =>
  debts.some((d1) =>
    debts.every(
      (d2) =>
        d1.accountNumber === d2.accountNumber ||
        (d1.currentBalance === d2.currentBalance && d1.lenderName === d2.lenderName),
    ),
  );
