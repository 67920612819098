import { Debt } from '../../interfaces';
import { ELIGIBLE_UNSECURED_DEBT_ACCOUNT_TYPES } from './eligibleUnsecuredDebtAccountTypes';
import { getAllOpenHouseholdDebtsByAccountTypes } from './getAllOpenHouseholdDebtsByAccountTypes';

export const getAllOpenHouseholdIneligibleDebts = (borrowerDebts: Debt[] = [], spouseDebts: Debt[] = []): Debt[] => {
  const debts = getAllOpenHouseholdDebtsByAccountTypes(
    borrowerDebts,
    spouseDebts,
    ELIGIBLE_UNSECURED_DEBT_ACCOUNT_TYPES,
  );
  return debts.filter((debt) => !debt.isEligibleForDmp);
};
