import { Collection } from '../../interfaces';
import { areCollectionsShared } from './areCollectionsShared';

export const getAllHouseholdCollections = (
  borrowerCollections: Collection[] = [],
  spouseCollections: Collection[] = [],
): Collection[] => {
  const householdCollections: Collection[] = [];

  for (const borrowerCollection of borrowerCollections) {
    if (['individual', 'primary', 'jointContractLiability'].includes(borrowerCollection.ecoaDesignator)) {
      householdCollections.push(borrowerCollection);
    }
  }

  for (const spouseCollection of spouseCollections) {
    if (['individual', 'primary', 'jointContractLiability'].includes(spouseCollection.ecoaDesignator)) {
      const isCollectionAccountedForByBorrower = householdCollections.find((collection) =>
        areCollectionsShared(collection, spouseCollection),
      );

      if (!isCollectionAccountedForByBorrower) {
        householdCollections.push(spouseCollection);
      }
    }
  }

  return householdCollections;
};
