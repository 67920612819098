export enum environments {
  americanFinancialServices = 'afs',
  apprisen = 'apprisen',
  cambridgeCreditCounseling = 'cambridge',
  consumerCreditCounselingServicesOfMaryland = 'cccsmd',
  consumerEducationServicesInc = 'cesi',
  creditOrg = 'creditorg',
  lutheranSocialServiceOfMinnesota = 'lssminn',
  main = 'main',
  navicoreSolutions = 'navicore',
  staging = 'staging',
}
