export enum carmelTransactionStatuses {
  corrected = 'Corrected',
  excepted = 'Excepted',
  pending = 'Pending',
  remitted = 'Remitted',
  returned = 'Returned',
  scheduled = 'Scheduled',
  settled = 'Settled',
  updated = 'Updated',
}
