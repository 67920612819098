import { DateTime } from 'luxon';
import { PlaidTransaction } from '../../interfaces';
import { isIncome } from '../plaidTransaction/isIncome';

export const getIncomeTransactions = (
  plaidTransactions: PlaidTransaction[],
  includeCurrentMonth = false,
): PlaidTransaction[] => {
  if (!plaidTransactions || plaidTransactions.length === 0) return null;

  if (plaidTransactions.some((transaction) => !transaction.date)) return null;

  const now = DateTime.now();
  const startDate = now.minus({ months: 3 }).startOf('month');
  const endDate = now.minus({ months: includeCurrentMonth ? 0 : 1 }).endOf('month');

  const incomeTransactions = plaidTransactions
    .filter((t) => isIncome(t))
    .filter((t) => DateTime.fromISO(t.date) >= startDate && DateTime.fromISO(t.date) <= endDate)
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .filter((currentTransaction, index, transactions) => {
      if (index === 0) {
        return true;
      }

      const previousTransaction = transactions[index - 1];

      if (
        currentTransaction.amount === previousTransaction.amount &&
        currentTransaction.date === previousTransaction.date &&
        currentTransaction.name == previousTransaction.name
      ) {
        return false;
      }

      return true;
    });

  return incomeTransactions;
};
