import { DateTime } from 'luxon';
import { getAverageDaysBetweenIncomeTransactions } from './getAverageDaysBetweenIncomeTransactions';
import { getIncomeTransactions } from './getIncomeTransactions';
import { getNextBusinessDay } from '../date/getNextBusinessDay';
import { isBusinessDay } from '../date/isBusinessDay';
import { PlaidTransaction } from '../../interfaces';

export const getNextPayday = (plaidTransactions: PlaidTransaction[]): string => {
  if (!plaidTransactions || plaidTransactions.length === 0) return null;

  if (plaidTransactions.some((transaction) => !transaction.date || !Number.isFinite(transaction.amount))) return null;

  const incomeTransactions = getIncomeTransactions(plaidTransactions, true);

  if (!incomeTransactions || incomeTransactions.length === 0) return null;

  const averageDaysBetweenIncomeTransactions = getAverageDaysBetweenIncomeTransactions(incomeTransactions);

  if (!averageDaysBetweenIncomeTransactions) return null;

  const sortedIncomeTransactions = incomeTransactions.sort((a, b) => {
    if (a.date < b.date) return -1;
    if (a.date > b.date) return 1;
    return 0;
  });

  const mostRecentIncomeTransaction = sortedIncomeTransactions[sortedIncomeTransactions.length - 1];

  let nextPayday = DateTime.fromISO(mostRecentIncomeTransaction.date).plus({
    days: averageDaysBetweenIncomeTransactions,
  });

  while (nextPayday < DateTime.now()) {
    nextPayday = nextPayday.plus({ days: averageDaysBetweenIncomeTransactions });
  }

  if (!isBusinessDay(nextPayday.toISODate())) {
    return getNextBusinessDay(nextPayday.toISODate());
  }

  return nextPayday.toISODate();
};
