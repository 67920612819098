import { PlaidTransaction } from '../../interfaces';
import { DateTime } from 'luxon';

export const getAccountAgeInMonths = (plaidTransactions: PlaidTransaction[]): number => {
  if (!plaidTransactions || plaidTransactions.length === 0) return null;

  if (plaidTransactions.some((transaction) => !transaction.date)) return null;

  const plaidTransactionsSortedByDateAsc = plaidTransactions.sort((a, b) => {
    const aDate = DateTime.fromISO(a.date);
    const bDate = DateTime.fromISO(b.date);

    return aDate < bDate ? -1 : 1;
  });

  const oldestPlaidTransaction = plaidTransactionsSortedByDateAsc[0];

  const dateOpened = DateTime.fromISO(oldestPlaidTransaction.date);

  const numberOfMonthsbetweenNowAndDateOpened = dateOpened.diffNow('months').as('months') * -1;

  return Math.round(numberOfMonthsbetweenNowAndDateOpened * 100) / 100;
};
