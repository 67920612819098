import { Collection } from '../../interfaces';

export const areCollectionsShared = (...collections: Collection[]): boolean =>
  collections.some((c1) =>
    collections.every(
      (c2) =>
        c1.accountNumber === c2.accountNumber ||
        (c1.currentBalance === c2.currentBalance && c1.subscriberName === c2.subscriberName),
    ),
  );
