import { PlaidTransaction } from '../../interfaces';
import { getIncomeTransactions } from './getIncomeTransactions';

export const getAverageDaysBetweenIncomeTransactions = (plaidTransactions: PlaidTransaction[]): number | null => {
  if (!plaidTransactions || plaidTransactions.length === 0) return null;

  if (plaidTransactions.some((transaction) => !transaction.date)) return null;

  const incomeTransactions = getIncomeTransactions(plaidTransactions, true);

  if (!incomeTransactions || incomeTransactions.length < 2) return null;

  const incomeTransactionsSortedSmallestToLargest = incomeTransactions.sort(
    (transactionOne, transactionTwo) => transactionTwo.amount - transactionOne.amount,
  );

  const transactionsWithoutOutliers = incomeTransactionsSortedSmallestToLargest.filter(
    (transaction) => transaction.amount <= -200,
  );

  if (transactionsWithoutOutliers.length === 0) {
    return null;
  }

  const incomeTransactionsSorted = transactionsWithoutOutliers.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );

  const daysBetweenTransactionsOccurrencesMap = {};
  const hoursPerDay = 24;
  const millisecondsPerSecond = 1000;
  const minutesPerHour = 60;
  const secondsPerMinute = 60;

  const millisecondsPerDay = millisecondsPerSecond * secondsPerMinute * minutesPerHour * hoursPerDay;

  incomeTransactionsSorted.forEach((transaction, index, array) => {
    if (index === 0) {
      return;
    }

    const previousTransaction = array[index - 1];

    const millisecondsBetweenTransactions =
      new Date(transaction.date).getTime() - new Date(previousTransaction.date).getTime();

    const daysBetweenTransactions = millisecondsBetweenTransactions / millisecondsPerDay;

    if (daysBetweenTransactions === 0) {
      return;
    }

    if (daysBetweenTransactionsOccurrencesMap[daysBetweenTransactions] === undefined) {
      daysBetweenTransactionsOccurrencesMap[daysBetweenTransactions] = 0;
    }

    daysBetweenTransactionsOccurrencesMap[daysBetweenTransactions] += 1;
  });

  const mostCommonNumberOfDaysBetweenTransactions = Object.keys(daysBetweenTransactionsOccurrencesMap).reduce(
    (maxKey, currentKey) => {
      const currentMax = daysBetweenTransactionsOccurrencesMap[maxKey];
      const currentValue = daysBetweenTransactionsOccurrencesMap[currentKey];

      if (currentValue === currentMax) {
        return currentKey > maxKey ? currentKey : maxKey;
      }

      return currentValue > currentMax ? currentKey : maxKey;
    },
  );

  const averageDaysBetweenIncomeTransactions = parseInt(mostCommonNumberOfDaysBetweenTransactions);

  return averageDaysBetweenIncomeTransactions;
};
